<template>
  <div>欢迎使用杉谷科技检测报警系统！</div>
</template>

<script>
export default {
  name: "Home"
}
</script>

<style scoped>

</style>
